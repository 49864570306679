export default {
  home: {
    title: 'Local Builder in Northants',
    description:
      'A trusted local builder for over 35 years, AGHicks Building Services covers Northampton and the surrounding areas. Get in touch for a free quote today.',
  },
  projects: {
    title: 'Project portfolio',
    description:
      'A builder with over 35 years of experience covering Northampton and the surrounding areas, browse our portfolio of work to see how we can transform your home.',
  },
  about: {
    title: 'About Us',
    description:
      'For over 35 years we’ve been providing building services to the Northamptonshire area. Find out more about us and get a free quote today.',
  },
  contact: {
    title: 'Contact Us',
    description:
      'With over 35 years as a friendly and reliable local builder, we’re happy to discuss building projects of all shapes and sizes. Contact us for a free quote today.',
  },
  project: {
    title: 'Project',
    description:
      'A builder with over 35 years of experience covering Northampton and the surrounding areas, browse our portfolio of work to see how we can transform your home.',
  },
}
